<template>
  <main class="layout-main">

    <div class="vld-parent">
      <loading :active="isLoading"
      :can-cancel="true"
      :loader="bars"
      :opacity='0.3'
      :on-cancel="onCancel"
      :is-full-page="fullPage"><img src="/image/loading.svg"></loading>
    </div>

    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/works.svg" alt="Works"><span class="sub">制作実績</span></h1>
        </div>
      </div>

      <section class="container-bg">
        <div class="container-wrapper container-pad -works ">

          <div class="copy-fukidashi"><p class="comment"><img src="@/assets/image/works/fukidashi.svg" alt=""></p></div>
          <p class="copy-caution">※一部の実績のみを掲載させて頂いております</p>

          <ul class="filter-cat">
            <li :class="{'-active': $route.path === '/works'}"><router-link to="/works"  @click="showCat()">ALL</router-link></li>
            <li :class="{'-active': $route.path === '/works/planning'}"><router-link to="/works/planning" @click="showCat('planning')">企画・発想</router-link></li>
            <li :class="{'-active': $route.path === '/works/promotion'}"><router-link to="/works/promotion" @click="showCat('promotion')">プロモーション</router-link></li>
            <li :class="{'-active': $route.path === '/works/digital'}"><router-link to="/works/digital" @click="showCat('digital')">デジタルマーケティング</router-link></li>
            <li :class="{'-active': $route.path === '/works/casting'}"><router-link to="/works/casting" @click="showCat('casting')">キャスティング</router-link></li>
            <li :class="{'-active': $route.path === '/works/develop'}"><router-link to="/works/develop" @click="showCat('develop')">新規事業開発</router-link></li>
          </ul>

          <transition-group name="work" tag="ul" class="grid-works "
            @before-enter="beforeEnter"
            @after-enter="afterEnter"
            @enter-cancelled="afterEnter"
            @before-leave="beforeLeave"
             ref="worklist"
            >
            <li v-for="(post,index) in posts.data" :key="index" :data-index="index">
              <router-link :to="{ name: 'WorkDetail', params: { id: post.id }}">
              <div class="card-block -hasbg">
                <div class="meta"><time v-if="post.date">{{ setDate(post.date ) }}</time></div>
                <div class="thumb" v-if="post.acf"><img src="@/assets/image/trans.png" alt="" :style="{backgroundImage:'url(' + post.acf.w_main_image.url + ')' }"></div>
                <div class="numbertitle" v-if="post.title">{{post.title.rendered}}</div>
              </div>
              </router-link>
              <router-link :to="{name: 'Service',hash: '#service-menu'}">
              <ul class="meta-cat">
                <template  v-for="(cat, idx ) in post.category_detail" :key="idx">
                <li v-if="cat.me.parent > 0"><span class="icon" :class='`${cat.me.category_nicename}`'>{{cat.me.name}}</span></li>
                </template>
              </ul>
              </router-link>
            </li>
          </transition-group>

          <div class="load-more" v-bind:class="{'-show':isMore}"><span class="button-link" @click="getMore" >LOAD MORE</span></div>

          <!--div class="pager">
              <VueTailwindPagination
                :current="currentPage"
                :total="total"
                :per-page="perPage"
                @page-changed="onPageClick($event)"
              />
          </div-->
        </div>
      </section>


  </div>
  </main>
</template>

<script>
import { onMounted, onUpdated, onUnmounted,reactive ,ref } from 'vue'
import axios from "axios"
import moment from 'moment'
//import "@ocrv/vue-tailwind-pagination/dist/style.css";
//import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import { useRoute } from 'vue-router'
//import { useRouter } from 'vue-router'
import Loading from 'vue-loading-overlay';
require( 'vue-loading-overlay/dist/vue-loading.css')


//app.config.globalProperties.foo = 'bar'

export default {

  components: {
    Loading
    //VueTailwindPagination
  },
  setup() {
    //alert(window.vueApp.config.globalProperties.foo)

    const route = useRoute()
    //const router = useRouter()

    const posts = reactive({
      data:null,
    })
    const currentPage = ref(1)
    const perPage = ref(100)
    const total = ref(0)
    const cat  =  ref(2)
    const isLoading = ref(false);
    const isMore = ref(false);
    const fullPage = ref(true);
    const API_HOST = process.env.VUE_APP_APIRESTHOST;
    const worklist = ref(null)

    /*
    const onPageClick = ( event ) => {
      currentPage.value = event;
      getItems( currentPage.value )

      getWorkItem( currentPage.value )
      .then( response => {
        posts.data = response.data
      }).catch( error => {
        console.log(error)
      })
    }
    */


    //http://onebetter.cotori.co/c/wp-json/wp/v2/categories
    /*
    const getItems = ( page ) => {

      // history back or router.go(-1) check
      if( router.from.name && router.from.name.indexOf("WorkDetail") >= 0 ){
        worklist.value.$el.classList.add('-notransition')
        isLoading.value = false
      }else{
        worklist.value.$el.classList.remove('-notransition')
        isLoading.value = true
        clear()
      }

      let url = '//' + API_HOST + '/c/wp-json/wp/v2/posts?per_page=100&order=asc&categories='+cat.value+'&per_page='+ perPage.value +'&page=' + page

      axios.get(url)
      .then( response => {
        posts.data = response.data
        total.value = response.headers['x-wp-total']
        setTimeout(() => {
          isLoading.value = false
        }, 50)
      })
    }
    */

    // post

    const getWorkItem = ( page ) => {
      let url = '//' + API_HOST + '/c/wp-json/wp/v2/posts?per_page=100&order=asc&categories='+cat.value+'&per_page='+ perPage.value +'&page=' + page
      try {
        return (axios.get(url))
      }catch (error) {
        throw error.response.status
      }
    }

    const getMore = ()=> {
      countUp()
      isLoading.value = true
      getWorkItem(currentPage.value)
      .then( response => {
        for(const item of response.data) {
          posts.data.push( item )
          isLoading.value = false
        }
        toggleMore()
      }).catch( error => {
        console.log(error)
        isLoading.value = false
      })
    }

    const showCat = (( id ) => {
      clear()
      switch( id  ){
        case 'develop':
          cat.value = 7
          //getItems(1)
          break
        case 'casting':
          cat.value = 6
          //getItems( 1 )
          break
        case 'promotion':
          cat.value = 4
          //getItems( 1 )
          break
        case 'planning':
          cat.value = 3
          //getItems( 1  )
          break
        case 'digital':
          cat.value =  5
          //getItems( 1 )
          break
        default:
          cat.value = 2;
          //getItems( 1  )
          break
      }
      isLoading.value = true
      getWorkItem(currentPage.value)
      .then( response => {
        posts.data = response.data
        isLoading.value = false
        total.value = response.headers['x-wp-total']
        toggleMore()
      }).catch( error => {
        console.log(error)
        isLoading.value = false
      })
    })

    const toggleMore = (( ) => {
      if( posts.data.length == total.value ){
        isMore.value = false
      }else{
        isMore.value = true
      }
    })

    const setDate = ((date) => {
      //return moment(date).format('YYYY.MM.DD')
      return moment(date).format('YYYY')
    })

    const clear = (() => {
      currentPage.value = 1
      posts.data = null
    })

    // loading

    const onCancel= ()=> {
      isLoading.value = false;
    }

    const beforeEnter = ((el) => {
      //console.log( el  );
      el.style.transitionDelay = 100 * parseInt(el.dataset.index - ((currentPage.value - 1) * perPage.value ), 10) + 'ms'
      /*
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth"
      });
      */
    })

    const afterEnter = ((el) => {
      el.style.transitionDelay = ''
    })

    const beforeLeave = (() => {
      //el.style.transitionDelay = 10 * parseInt(el.dataset.index, 10) + 'ms'
    })

    const countUp = (() => {
      currentPage.value++
      //alert(currentPage.value)
    })

    onMounted(() => {
        //console.log(router)
        showCat(route.params['id'])
      //isList.value = true
    })
    onUpdated(() => {
    })
    onUnmounted(() => {
    })
    return{
      posts,
      //onPageClick,
      //getItems,
      currentPage,
      perPage,
      total,
      setDate,
      beforeEnter,
      afterEnter,
      beforeLeave,
      route,
      showCat,
      isLoading,
      fullPage,
      onCancel,
      worklist,
      getMore,
      isMore
    }
  }
}

</script>

<style lang="scss">
  .work-item {

  }
  .work-enter-active,
  .work-leave-active {
    transition: all 0.2s ease-in-out;
  }

  .work-enter-from{
    /*.work-leave-to */
    opacity: 0;
    transform: translateY(20px);
  }
  .work-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }
  .work-leave-from {
    opacity: 1;
    transform: translateY(0px);
  }
  .-notransition .work-enter-active,
  .-notransition .work-leave-active{
    transition:none;
  }
  .-notransition .work-leave-to,
  .-notransition .work-enter-from{
    opacity: 1;
    transform:none;
  }

/*
  .pager section{
    background:none;
    padding: 0;
    justify-content:center;
  }
  .pager section > *:last-child{
    display: none;
  }
  .pager .-rotate-45,
  .pager .rotate-45,
  .pager section li span.transform{
    transform:none;
  }
  .pager section li{
    margin:0 5px;
    background:#fff;
    border-radius:50%;
    border:1px solid rgba( 0, 0, 0, 0.1);
    overflow:hidden;
    width:42px !important;
    height:42px !important;
    display:flex;
    align-items:center;
    line-height:1;
    justify-content:center;
    padding:0;
  }
  .pager section ul li a div.bg-gradient-to-r{
    background:yellow
  }
  .pager section ul li a div div,
  .pager section ul li a div span{
    display: block;
    padding: 12px 14px;
    width:auto;
    height:auto;
    line-height:1;
    font-family:sans-serif;
    &:hover{
      background-color:#ccc;
      color:#fff;
    }
  }
  .pager section ul li a div svg{
    width:13px;
    height:16px;
  }
  .pager section ul *{
    display:inline-block;
    height:auto;
    width:auto;
  }
  */
</style>



